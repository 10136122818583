import React, { useState } from 'react';
import './index.scss';
import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout'
import { Card } from '@/components/card';
import { DigitalBlock } from '@/components/digitalBlock';
import { Icon } from '@/components/icon';
import { Circle } from '@/components/circle';
import * as Charts from '@/components/charts';
import { handleDataForLineChart, searchParams } from '@/utils';
import { useRequest } from 'ahooks';
import { EnvironmentApi, Production } from '@/api/request';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { formatLineChartXAxis } from '@/utils/time';
import _ from 'lodash';
import { ExportButton } from '@/components/exportButton';
import FormatterType from '@/components/charts/formatterType';
import { Button } from 'antd-mobile';
import moment from 'moment';
import DatePicker from 'react-mobile-datepicker'
const lineCode = searchParams().lineCode;
const Capacity = () => {
    const [currentTime, setCurrentTime] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [isShowPrevious, setIsShowPrevious] = useState(false)
    const [previousChart, setPreviousChart] = useState([])
    const [previousMaxCount, setPreviousMaxCount] = useState(0)
    const [previousMaxTime, setPreviousMaxTime] = useState('')
    const [previousDate, setPreviousDate] = useState('')
    const { data, loading, error, run } = useRequest(() => {
        return Promise.all([
            Production.getCapacity(lineCode),
            Production.getTodayCountByMinutes(lineCode)
        ])
    }, { manual: false })
    const pageName = '实时产能'
    if (loading) return <BasicLayout name={pageName}> <Loading /> </BasicLayout>
    if (error) return <BasicLayout name={pageName}> <Error /> </BasicLayout>
    let [capacityCount, capacityChart]: any = data;
    const reloadCapacity = () => {
        run()
        //获取最新时间
        let time = moment().format('YYYY/MM/DD HH:mm:ss')
        setCurrentTime('数据已刷新 ' + time)

    }

    const onClickSelected = () => {
        setIsOpen(true)
    }
    const selectDate = (value) => {
        setIsShowPrevious(false)
        setPreviousDate(moment(value).format('YYYY-MM-DD'))
        Production.getBeforeTodayCapacityByMinutes(lineCode, moment(value).format('YYYY-MM-DD')).then(res => {
            //获取最大值
            getMaxCountAndTime(res as Array<any>)
            setPreviousChart(res as any)
            setIsShowPrevious(true)
            setIsOpen(false)
        })
    }

    const getMaxCountAndTime = (data: Array<any>) => {
        console.log(data)
        let count = 0
        let time = ''
        data.forEach(item => {
            if (item.count > count) {
                count = item.count
                time = moment(item.timeRange.endTime).subtract(1, 'hours').format('HH:mm') + "-" + moment(item.timeRange.endTime).format('HH:mm')
            }
        })

        setPreviousMaxCount(count)
        setPreviousMaxTime(time)
    }
    return (
        <BasicLayout name={pageName}>
            <EasyGridLayout width={355} height={90} rows={1} columns={2} padding={[0, 0, 0, 0]} center gap={10} margin={[0, 0, 50, 0]}>
                <Card customBar={<React.Fragment />} >
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='capacity.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={capacityCount.lastHourCount} name='最近一小时' unit='双/小时' size={0.85} spacing={10} />
                    </LineLayout>
                </Card>

                <Card customBar={<React.Fragment />}>
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='capacity.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={capacityCount.maxAmountOfOneHour.count} name='时段最高' unit='双/小时' size={0.85} spacing={10} />
                    </LineLayout>
                    <div style={{ position: 'absolute', top: '140px', left: '220px', fontSize: '25px' }}>{capacityCount.maxAmountOfOneHour.beginTime + '-' + capacityCount.maxAmountOfOneHour.endTime}</div>
                </Card>

            </EasyGridLayout>
            <LineLayout justify='around' padding={[10, 0, 15, 0]} gap={10} direction='column'>
                <Card height={250} width={355} title='产能趋势' icon='line.png'>
                    <Charts.BasicLineChart width={355} height={200}
                        dataX={capacityChart.map((item: any) => {
                            return moment(item.timeRange.endTime).format("HH:mm")
                        })}
                        dataY={handleDataForLineChart(7, capacityChart).yAxis.map((item: any) => {
                            return item === undefined || item === null ? item : _.round(item);
                        })}
                        formatter={FormatterType.CapacityMoment}
                    />
                </Card>
            </LineLayout>

            <Button type='primary' onClick={reloadCapacity}>点击刷新</Button>
            <p style={{ color: "#fff", paddingTop: "10px", textAlign: "center", fontSize: "20px", marginBottom: "20px" }}>{currentTime}</p>
            <Button type='primary' onClick={() => { onClickSelected() }}  >过往查询</Button>
            <DatePicker
                theme='dark'
                isOpen={isOpen}
                min={new Date(2023, 1, 1)}
                onSelect={selectDate}
                onCancel={() => { setIsOpen(false) }} />
            {
                isShowPrevious ?

                    <>
                        <EasyGridLayout width={355} height={90} rows={1} columns={2} padding={[0, 0, 0, 0]} center gap={10} margin={[20, 0, 10, 0]}>
                            <Card customBar={<React.Fragment />}>
                                <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                                    <Circle>
                                        <Icon iconName='capacity.png' size={1.2} />
                                    </Circle>
                                    <DigitalBlock type='valueStacking' value={previousMaxCount} name='时段最高' unit='双/小时' size={0.85} spacing={10} />
                                </LineLayout>
                                <div style={{ position: 'absolute', top: '140px', left: '220px', fontSize: '25px' }}>{capacityCount.maxAmountOfOneHour.beginTime + '-' + capacityCount.maxAmountOfOneHour.endTime}</div>
                            </Card>
                            <div>
                                <p style={{ color: "#fff", fontSize: "25px" }}>{previousDate}</p>
                                <p style={{ color: "#fff", fontSize: "25px" }}>{previousMaxTime}</p>
                            </div>
                        </EasyGridLayout>
                        <LineLayout justify='around' padding={[10, 0, 15, 0]} gap={10} direction='column' >
                            <Card height={250} width={355} title='产能趋势' icon='line.png'>
                                <Charts.BasicLineChart width={355} height={200}
                                    dataX={previousChart.map((item: any) => {
                                        return moment(item.timeRange.endTime).format("HH:mm")
                                    })}
                                    dataY={handleDataForLineChart(7, previousChart).yAxis.map((item: any) => {
                                        return item === undefined || item === null ? item : _.round(item);
                                    })}
                                    formatter={FormatterType.CapacityMoment}
                                />
                            </Card>
                        </LineLayout>
                    </> : null
            }
        </BasicLayout>
    )
}

export { Capacity }