import service from '../service'
import { timeRanges } from '@/utils/time'

/**
 * 获取今日的呼叫次数
 */


const Production = {
    /**
     * 获取总产量
     * @param lineCode 
     * @returns 
     */
    getTotalCount: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Production/GetTotalCount',
            params: { lineCode: lineCode }
        })
    },
    /**
 * 获取今日产量
 * @param lineCode 
 * @returns 
 */
    getTodayCount: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Production/GetTodayCount',
            params: { lineCode: lineCode }
        })
    },



    /**
     * /
     * 获取最近15天产量
     * @param lineCode 
     * @returns 
     */
    getCountByLast15Days: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Production/GetCountByTimeRange',
            params: { lineCode: lineCode, ...timeRanges.lastDays15(), timeRangeType: 2 }
        })
    },


    /**
     * 根据时间区间类型和获取产量
     * @param lineCode 产线编号
     * @param timeRange 时间区间类型
     * @returns 
     */
    getCountByTimeRange: (lineCode?: string, timeRange?: any) => {
        return service({
            url: '/Production/GetCountByTimeRange',
            params: { lineCode, ...timeRange }
        })
    },


    getTodayCountByMinutes: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Production/GetCountByTimeRange',
            params: { lineCode: lineCode, ...timeRanges.today2(), timeRangeType: 7, }
        })
    },

    //获取今日之前的分钟产量
    getBeforeTodayCapacityByMinutes: (lineCode?: string, selectDate?: string): Promise<any> => {
        return service({
            url: '/Production/GetCountByTimeRange',
            params: { lineCode: lineCode, beginTime: selectDate + " " + "06:30:00", endTime: selectDate + " " + "23:00", timeRangeType: 7, }
        })
    },


    /**
     * 根据时段和产线获取产能
     * @param lineCode 
     * @param timeRange 
     * @returns 
     */
    getCapacityInPairPerHourByTimeRange: (lineCode?: string, timeRange?: any) => {
        return service({
            url: '/Production/GetCapacityInPairPerHourByTimeRange',
            params: { lineCode, ...timeRange }
        })
    },


    /**
     * 获取各种产量的 统计值
     * {
  "count": 0,
  "capacity": 0,
  "capacityUnit": null,
  "taktBySecondsPerPair": 0,
  "shoeTypeCount": 0,
  "shoeTypeAndSizeCount": 0
}
     * @param lineCode 
     * @param timeRange 
     * @returns 
     */
    getStatisticValues: (lineCode?: string, timeRange?: any) => {
        return service({
            url: '/Production/GetStatisticValues',
            params: { lineCode, ...timeRange }
        })
    },


    /**
     * 获取上线鞋形状
     * @param lineCode 
     * @param timeRange 
     * @returns 
     */
    getShoeTypeCountByTimeRange: (lineCode?: string, timeRange?: any) => {
        return service({
            url: 'Production/GetShoeTypeCountByTimeRange',
            params: { lineCode, ...timeRange }
        })
    },


    /**
     * 获取车间各个产线的产能根据时间区间
     * @param timeRange 
     * @returns 
     */
    getallLineCount: (timeRange?: any) => {
        return service({
            url: 'Production/GetCountByTimeRangeByLineCode',
            params: { ...timeRange }
        })
    },

    /**
     * 获取车间各个产线的产能
     * @param timeRange 
     * @returns 
     */
    getallLineCapacity: (timeRange?: any) => {
        return service({
            url: 'Production/GetCapacityInPairPerHourByTimeRangeByLineCode',
            params: { ...timeRange }
        })
    },

    /**
     * /获取按照鞋型的上线统计
     * @param timeRange 
     * @returns 
     */
    getCountByShoeType: (lineCode: string, timeRange?: any) => {
        return service({
            url: 'Production/GetCountByShoeType',
            params: { ...timeRange }
        })
    },

    /**
     * 获取产能
     * 
     */

    getCapacity: (lineCode: string) => {
        return service({
            url: 'Production/GetProductionHourStatisticsByMinutesCacl',
            params: { lineCode }
        })
    }




}

export default Production







